<template>
  <v-dialog v-model="showingScoresModal" persistent fullscreen hide-overlay>
    <v-card>
      <v-app-bar class="elevation-0 instrument-app-bar">
        <v-app-bar-title class="text-h5 grey">{{$t('Puntaje')}}</v-app-bar-title>
        <v-spacer></v-spacer>
        <span class="mx-2">{{$t('Escuela')}}: {{ results.school ? results.school : ' - ' }}</span>
        <span class="mx-2">{{$t('Region')}}: {{ results.region ? results.region : ' - ' }}</span>
        <span class="mx-2">{{$t('País')}}: {{ results.country ? countryParser[results.country] : ' - ' }}</span>
        <span class="mx-2">{{$t('Título')}}: {{ results.evaluation_name ? results.evaluation_name : ' - ' }}</span>
        <v-spacer></v-spacer>
        <div class="d-flex" v-if="(!isActionPlan && tab !== 0) || isActionPlan">
          <v-btn outlined text color="info" @click="printFullSection()">
            {{ $t('Imprimir sección') }}
            <v-icon class="ml-2">{{ icons.mdiPrinter }}</v-icon>
          </v-btn>
          <v-btn icon color="warning" :aria-label="$t('Ayuda')" @click.stop="$refs.print_helper_modal.openModal()">
            <v-icon>{{ icons.mdiHelpCircleOutline }}</v-icon>
          </v-btn>
        </div>
        <v-btn icon color="tertiary" @click="close()" aria-label="close-modal">
          <v-icon>{{icons.mdiClose}}</v-icon>
        </v-btn>
      </v-app-bar>
      <v-tabs
        v-model="tab"
        background-color="primary"
        fixed-tabs
        dark
        active-class="active-tab"
        @change="resize"
      >
        <v-tab v-if="!isActionPlan" style="text-transform: none; letter-spacing: 0; font-size: 1.2rem;" :key="0">{{$t('General')}}</v-tab>
        <v-tab v-if="!isActionPlan" style="text-transform: none; letter-spacing: 0; font-size: 1.2rem;" :key="1">{{$t('Por categoria')}}</v-tab>
        <v-tab v-if="isActionPlan" style="text-transform: none; letter-spacing: 0; font-size: 1.2rem;" :key="2">{{$t('Plan de acción')}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- TAB GENERAL CHARTS -->
        <v-tab-item v-if="!isActionPlan" :key="0">
          <v-card-text>
            <v-card-title class="text-h6 grey lighten-2 justify-center" style="position: relative;">
              {{$t('Resultados generales')}}
              <div class="d-flex print-chart-actions">
                <v-btn outlined text color="info" class="" @click="printGeneralCharts()">
                  {{ $t('Imprimir gráficos') }}
                  <v-icon class="ml-2">{{ icons.mdiPrinter }}</v-icon>
                </v-btn>
                <v-btn icon color="warning" :aria-label="$t('Ayuda')" @click.stop="$refs.print_helper_modal.openModal()">
                  <v-icon>{{ icons.mdiHelpCircleOutline }}</v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-divider class="mb-3"></v-divider>
            <v-row >
              <v-col cols="12" lg="4" class="general-description flex-column">
                <v-card-subtitle v-for="(data, idx) in evaluationData" :key="`evaluation_data${idx}`" class="margins-breakpoints">
                  <span class="Secondary--text font-weight-bold text-subtitle-1">{{ data }}</span>
                </v-card-subtitle>

                <v-card-subtitle class="margins-breakpoints show-notes" v-if="results.note">
                  <span class="Secondary--text text-subtitle-2">
                    {{ $t('Nota')+':' }} {{ results.note.note }}
                  </span>
                </v-card-subtitle>
                <v-btn color="info" id="notes_button" class="mb-3" outlined @click.stop="$refs.notes_modal.openModal()">
                  {{ results.note ? $t('Editar') : $t('Crear') }} {{ $t('Nota') }}
                 <v-icon class="ml-2">{{ icons.mdiCommentMultipleOutline }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="7" class="d-flex flex-column">
                <div class="mx-auto">
                  <vue-apex-charts
                    ref="radial_bar_chart"
                    type="radialBar"
                    height="500"
                    width="500"
                    :options="chartOptions.radialBarChartOptions"
                    :series="chartSeries.radialBarChartSeries"
                  />
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-title class="justify-center text-h6">{{$t('Puntaje de la categoría')}}</v-card-title>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" lg="6" class="apex-barchart-container">
                <vue-apex-charts
                  ref="bar_chart"
                  type="bar"
                  height="500"
                  width="100%"
                  :options="chartOptions.barChartOptions"
                  :series="chartSeries.generalScoreSeries"
                />
              </v-col>
              <v-col cols="12" lg="6" class="apex-radarchart-container">
                <vue-apex-charts
                  ref="radar_chart"
                  type="radar"
                  height="450"
                  width="100%"
                  :options="chartOptions.radarChartOptions"
                  :series="chartSeries.generalScoreSeries"
                />
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-title class="justify-center text-h6">{{$t('Distribución de los criterios de valoración')}}</v-card-title>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" lg="6" class="apex-radarchart-container">
                <vue-apex-charts
                  ref="principles_radar_chart"
                  type="radar"
                  height="450"
                  width="100%"
                  :options="chartOptions.principlesRadarChartOptions"
                  :series="chartSeries.principlesRadarChartSeries"
                />
              </v-col>
              <v-col cols="12" lg="6" class="apex-columnchart-container">
                <vue-apex-charts
                  ref="column_chart"
                  type="bar"
                  height="450"
                  width="100%"
                  :options="chartOptions.columnChartOptions"
                  :series="chartSeries.principlesRadarChartSeries"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>

        <!-- TAB CATEGORY CHARTS -->
        <v-tab-item v-if="!isActionPlan" :key="1">
          <v-card-text ref="categories_charts" :id="`category_${index}`" v-for="(category, index) in results.category_total" class="pb-10" :key="`category_${index}`">
            <v-divider></v-divider>
            <v-card-title class="justify-center">
              <div class="title-container text-h6">
                {{`${index+1} - ${ instrument === 'School_instrument' ? schoolCategoryParser[index+1] : preschoolCategoryParser[index+1]}` }}
              </div>
              <div class="d-flex ml-auto">
                <v-btn outlined text color="info" @click="printCategoryCharts(index)">
                  {{ $t('Imprimir gráficos') }}
                  <v-icon class="ml-2">{{ icons.mdiPrinter }}</v-icon>
                </v-btn>
                <v-btn icon color="warning" :aria-label="$t('Ayuda')" @click.stop="$refs.print_helper_modal.openModal()">
                    <v-icon>{{ icons.mdiHelpCircleOutline }}</v-icon>
                  </v-btn>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-row dense no-gutters>

              <v-col cols="12" md="6" xl="2" class="graphics-border mb-4 d-flex align-center justify-center">
                <vue-apex-charts class="pt-2 charts"
                  ref="category_total"
                  height="300"
                  :options="chartOptions.strokedRadialBarChart"
                  :series="chartSeries.categoryTotals[index]"
                />
              </v-col>

              <v-col cols="12" md="6" xl="5" class="apex-radarchart-container mb-4">
                  <vue-apex-charts class="mb-3 pt-8 charts"
                    ref="category_radar_chart"
                    type="radar"
                    height="500"
                    :options="chartOptions.radarChartOptions"
                    :series="chartSeries.categoryesRadarChartSeries[index]"
                  />
              </v-col>

              <v-col cols="12" md="8" xl="5" class="graphics-border apex-columnchart-container d-flex flex-column mx-auto">
                  <v-card-subtitle style="position: absolute; left: 50%; transform: translateX(-50%); padding-top: 10px">
                    {{$t('Principios')}}
                  </v-card-subtitle>
                  <vue-apex-charts class="pt-8 charts"
                    ref="category_column_chart"
                    type="bar"
                    height="500"
                    :options="chartOptions.columnChartOptions"
                    :series="chartSeries.categoryesRadarChartSeries[index]"
                  />

                  <v-btn
                    large
                    outlined
                    :color="$vuetify.theme.dark? 'warning' : 'primary'"
                    class="mb-3 mt-3 mx-6"
                    @click="toggleDropdown(index)"
                  >
                    <v-icon :style="{ transform: showDropdown[index] ? 'rotate(-180deg)' : null }">{{ icons.mdiChevronDown }}</v-icon>
                    {{$t('Por indicador')}}
                  </v-btn>
              </v-col>
            </v-row>

            <!-- Dropdown y tabla -->
              <!-- Contenido del dropdown -->
            <v-expand-transition>
              <div :class="{ 'hidden-indicators': !showDropdown[index] }">

                <v-row dense no-gutters class="row-with-border text-uppercase text-center text-subtitle-2">
                  <v-col > {{$t('Indicadores')}} </v-col>
                  <v-col > {{$t('Principios')}} </v-col>
                  <v-col > {{$t('Puntaje del indicador')}} </v-col>
                </v-row>
                <v-row dense no-gutters>
                  <v-col cols="8" class="px-0">
                    <v-simple-table class="charts">
                      <template v-slot:default>
                        <tbody>
                          <tr class="only-print">
                            <td>{{ $t('Indicadores') }}</td>
                            <td>{{ $t('Principios') }} </td>
                          </tr>
                          <tr v-for="(item, idx) in category.indicator_data" :key="`category_indicator_${idx}`">
                            <td>{{ `${item.name} - ${instrument === 'School_instrument' ? schoolIndicatorParser[item.name] : preschoolIndicatorParser[item.name]}` }}</td>
                            <td class="text-center">
                              <v-row class="px-5">
                                <v-col>{{ principlesValue(item.options, "A") }}</v-col>
                                <v-col>{{ principlesValue(item.options, "B") }}</v-col>
                                <v-col>{{ principlesValue(item.options, "C") }}</v-col>
                                <v-col>{{ principlesValue(item.options, "D") }}</v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="4" class="apex-barchart-container">
                    <vue-apex-charts class="charts"
                      ref="indicator_bar_chart"
                      type="bar"
                      height="100%"
                      width="100%"
                      :options="chartOptions.indicatorsBarChartOptions"
                      :series="chartSeries.indicatorsBarSeries[index]"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-tab-item>

        <!-- TAB ACTION PLAN -->
        <v-tab-item v-if="isActionPlan" :key="2" class="mb-4" style="min-height: 1000px;" :style="$vuetify.theme.dark ? 'background-color: #373258cc;' : 'background-color: #f6faff;'">
          <ScoresActionPlan
            ref="action_plan_tab"
            :generalDescription="generalDescription"
            :results="results"
            :icons="icons"
            :printer="printer"
            @reload-results="getResults"
          />
        </v-tab-item>

      </v-tabs-items>
      <v-divider ></v-divider>
      <v-card-actions >
        <v-spacer></v-spacer>
        <v-btn
          color="tertiary"
          outlined
          @click="close()"
        >
          {{$t('Cerrar')}}
        </v-btn>
      </v-card-actions>
    </v-card>

    <PrintHelperModal ref="print_helper_modal"/>
    <NotesModal
      ref="notes_modal"
      :evaluation_id="evaluation_id"
      :hasNote="!!results.note"
      @reload-evaluation-note="getNote"
    />
  </v-dialog>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { Printd } from 'printd'
// import {ref} from '@vue/composition-api'
import countriesParserMixin from '@/utils/countriesParserMixin';
import instrumentsParserMixin from '@/utils/instrumentsParserMixin';
import { mdiClose, mdiChevronDown, mdiHelpCircleOutline, mdiPencilOutline, mdiTrashCanOutline, mdiPlus, mdiPrinter, mdiCommentMultipleOutline, mdiCalendar } from '@mdi/js'
import NotesModal from '@/components/Evaluations/NotesModal.vue';
import PrintHelperModal from '@/components/Evaluations/PrintHelperModal.vue';
import ScoresActionPlan from './ScoresActionPlan.vue';
export default {
  data: () => {
    return {
      showingScoresModal: false,
      isActionPlan: false,
      evaluation_id: null,
      evaluationData: [],
      results: {},
      instrument: '',
      tab: null,
      generalDescription: null,
      printer: {},
      showDropdown: [],
      isFirstToggle: true,
      chartOptions: {
        radialBarChartOptions: {
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true
            },
            toolbar: {
              show:true,
              tools: {
                download: true
              },
              export: {
                png: {
                  filename: undefined
                }
              }
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '22px'
                }
              }
            }
          },
          grid: {
            padding: {
              top: -10
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              shadeIntensity: 0.4,
              type:'horizontal',
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0,55, 85]
            },
          },
          labels: ['Average Results'],
        },


        barChartOptions: {
          fill: {
            colors: ['#FFB81C']
          },
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              // barHeight: '40%',
            }
          },
          grid: {
            padding: {
              bottom: -40
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '18px',
              colors: ['#373636'],
            },
          },
          xaxis: {
            type: 'category',
            labels: {
              style: {
                colors: '#373636',
                // fontSize: '20px',
                // fontWeight: 'bold',
              }
            }

          }
        },

        indicatorsBarChartOptions: {
          colors: ['#FFB81C'],
          chart: {
            type: 'bar',
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '18px',
              colors: ['#373636'],
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              barHeight: '40%'
            }
          },
          grid: {
            padding: {
              bottom: -40
            }
          },
        },
        radarChartOptions: {
          chart: {
            type: 'radar'
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
            },
            background: {
              enabled: true,
              padding: 5,
              borderRadius: 3,
              opacity: 0.85,
            },
          },
          plotOptions: {
            radar: {
              size: 190,
              polygons: {
                strokeColors: '#e9e9e9',
                fill: {
                  colors: ['#F5F5F5', '#fff']
                }
              }
            }
          },
          grid: {
            padding: {
              bottom: -60
            }
          },
          colors: ['#FF4560'],
          markers: {
            size: 4,
            colors: ['#fff'],
            strokeColor: '#FF4560',
            strokeWidth: 2,
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val
              }
            }
          },
          xaxis: {
            type: 'category',
            labels: {
              style: {
                fontSize: '14px',
                fontWeight: 'bold',
              }
            }
          },
          yaxis: {
            show: false,
            // labels: {
            //   offsetX: 30,
            //   offsetY: 30,
            // }
          }
        },
        principlesRadarChartOptions: {
          chart: {
            type: 'radar',
            dataLabels: {
              style: {
                colors: ['#F1F1F1'],
                fontWeight: 'bold',
                fontSize: '16px'
              }
            }
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            radar: {
              size: 190,
              polygons: {
                strokeColors: '#e9e9e9',
                fill: {
                  colors: ['#F5F5F5', '#fff']
                }
              }
            }
          },
          colors: ['#FF4560'],
          markers: {
            size: 0,
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val
              }
            }
          },
          xaxis: {
            type: 'category',
          },
          yaxis: {
            show: false
          }
        },
        columnChartOptions: {
          chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '40%',
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              }
            },
            colors: ['#FFB81C'],
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val + "%";
              },
              offsetY: 0,
              style: {
                fontSize: '16px',
                colors: ["#304758"]
              }
            },

            xaxis: {
              type: 'category',
              position: 'bottom',
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              // tooltip: {
              //   enabled: true,
              // }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val + "%";
                }
              }

            },
        },
        strokedRadialBarChart: {
          chart: {
            height: 280,
            type: "radialBar",
            toolbar: {
              show:true,
              tools: {
                download: true
              },
              export: {
                png: {
                  filename: undefined
                }
              }
            }
          },

          series: [67],

          plotOptions: {
            radialBar: {
              hollow: {
                margin: 15,
                size: "70%"
              },

              dataLabels: {
                showOn: "always",
                name: {
                  offsetY: -10,
                  show: false,
                  color: "#888",
                  fontSize: "13px"
                },
                value: {
                  color: "#111",
                  fontSize: "30px",
                  show: true
                }
              }
            }
          },

          grid: {
            padding: {
              top: 40
            }
          },

          stroke: {
            lineCap: "round",
          },
          labels: ["Progress"]
        }

      }
    }
  },
  mixins: [countriesParserMixin, instrumentsParserMixin],
  methods: {
    async getResults(evaluation_id) {

      try {
        const results = await this.$api.getResults(evaluation_id)
        this.results = results
      } catch (error) {
        console.log(error);
      }
    },
    getNote(note) {
      if (note) {
        this.$set(this.results, 'note', note);
      } else {
        this.$set(this.results, 'note', null);
      }
      this.$forceUpdate()
    },
    async openScoresModal(evaluation, actionPlan) {
      this.isActionPlan = actionPlan ?? false
      this.evaluation_id = evaluation.id
      this.evaluationData.push(`${this.$t('Título')}: ${evaluation.name ? evaluation.name : ' - '}`,`${this.$t('País')}: ${this.countryParser[evaluation.nationality]}`, `${this.$t('Escuela')}: ${evaluation.school}`, `${this.$t('Período')}: ${evaluation.period ?? evaluation.date}`)
      this.instrument = evaluation.instrument

      //for printD purposes
      this.copyGeneralDescription()

      this.showingScoresModal = true

      try {

        await this.getResults(evaluation.id)

        this.chartSeries.totalScoreSeries = [this.results.program_total]

        this.chartSeries.generalScoreSeries[0].data = this.results.category_total.map(el => ({
          x: this.autoBreak(this.instrument === 'School_instrument' ? this.schoolCategoryParser[el.name] : this.preschoolCategoryParser[el.name]),
          y: el.value
        }));

        this.chartSeries.principlesRadarChartSeries[0].data = this.results.percentage_total.map(el => ({
          x: this.autoBreak(this.percentagesParser[el.principle]),
          y: el.percentage
        }))

        this.chartSeries.categoryTotals = this.results.category_total.map(category => [category.value]);

        this.chartSeries.categoryesRadarChartSeries = this.results.category_total.map((category) => {
          return [{
            data: category.percentage_principle.map((el) => ({
              x: this.autoBreak(this.percentagesParser[el.principle]),
              y: el.percentage
            })),
          }];
        });
        this.chartSeries.indicatorsBarSeries = this.results.category_total.map((category) => {
          return [{
            data: category.indicator_data.map((el) => ({
              x: el.name,
              y: el.total
            })),
          }];
        });

        if (this.$refs.bar_chart) {
          this.$refs.bar_chart.updateSeries([{
            data:this.chartSeries.generalScoreSeries[0].data
          }],false,true)
        }

        if (this.$refs.radial_bar_chart) {
          this.$refs.radial_bar_chart.updateSeries([
            this.chartSeries.totalScoreSeries
          ],false,true)
        }

        if (this.$refs.radar_chart) {
          this.$refs.radar_chart.updateSeries([{
            data:this.chartSeries.generalScoreSeries[0].data
          }],false,true)
        }

        if (this.$refs.principles_radar_chart) {
          this.$refs.principles_radar_chart.updateSeries([{
            data:this.chartSeries.principlesRadarChartSeries[0].data
          }],false,true)
        }

        if (this.$refs.category_total) {
          this.$refs.category_total.updateSeries([{
            data:this.chartSeries.categoryTotals
          }],false,true)
        }

        if (this.$refs.category_column_chart) {
          this.$refs.category_column_chart.updateSeries([{
            data:this.chartSeries.categoryesRadarChartSeries
          }],false,true)
        }

        if (this.$refs.category_radar_chart) {
          this.$refs.category_radar_chart.updateSeries([{
            data:this.chartSeries.categoryesRadarChartSeries
          }],false,true)
        }

        if (this.$refs.indicator_bar_chart) {
          this.$refs.indicator_bar_chart.updateSeries([{
            data:this.chartSeries.categoryesRadarChartSeries
          }],false,true)
        }

      } catch(e) {
        console.log(e)
      } finally {
        window.dispatchEvent(new Event('resize'));
      }
    },
    toggleDropdown(index) {
      this.$set(this.showDropdown, index, !this.showDropdown[index]);

      //resize window only on first dropdown opening (for consistent table aspect)
      if(this.isFirstToggle) {
        window.dispatchEvent(new Event('resize'));
        this.isFirstToggle = false
      }
    },
    principlesValue(options, string){
      const foundOption = options.find(el => el.principle === string)
      return foundOption ? `${foundOption.option} %` : '-'
    },
    close() {
      this.results = {}
      this.instrument = ''
      this.evaluationData = []
      this.tab = null
      this.showDropdown = []
      this.isFirstToggle = true
      this.showingScoresModal = false
      if (this.$refs.action_plan_tab) {
        this.$refs.action_plan_tab.questions = []
        this.$refs.action_plan_tab.activities = []
        this.$refs.action_plan_tab.goalsAndResult = []
      }
    },
    resize() {
      window.dispatchEvent(new Event('resize'));
    },

    autoBreak(label) {
      const maxLength = 16;
      const lines = [];

      if(label) {
        for (let word of label.split(" ")) {
          if (lines.length == 0) {
            lines.push(word);
          } else {
            const i = lines.length - 1
            const line = lines[i]
            if (line.length + 1 + word.length <= maxLength) {
              lines[i] = `${line} ${word}`
            } else {
              lines.push(word)
            }
          }
        }
      }


      return lines;
    },
    printGeneralCharts() {
      const cssText = `
        .show-notes {
          display: none;
        }

        .general-description {
          display:flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 15px;
          font-family: "AvenirNext", "Inter", sans-serif !important;
          font-size: 15px !important;
        }

        h2 {
          font-family: "AvenirNext", "Inter", sans-serif !important;
          font-size: 24px;
        }

        .page-break {
          page-break-after: always;
        }

        .apexcharts-toolbar, .apexcharts-tooltip, .apexcharts-yaxistooltip, .apexcharts-legend, .apexcharts-yaxistooltip{
          display: none !important;
          stroke: none !important;
        }

        .apexcharts-ycrosshairs{
          border: none !important;
          stroke: none !important;
        }

        .apexcharts-datalabel, .apexcharts-text {
          fill: #000 !important;
          font-size: 14px;
          font-weight: 700;
        }

      `
      let printContainer = document.createElement('div');
      printContainer.classList.add('charts-container')

      let chartRefs = ['radial_bar_chart', 'bar_chart', 'radar_chart', 'principles_radar_chart', 'column_chart',];

      printContainer.appendChild(this.generalDescription);

      // Crear un nuevo layout para la impresion de los graficos. solo con graficos y titulo
      chartRefs.forEach((ref, index) => {

        let chartContainer = document.createElement('div');
        chartContainer.classList.add("chart-print-container");

        if (ref === 'radial_bar_chart') {
          let title = document.createElement('h2');
          title.textContent = `${this.$t('Resultados generales')}`;
          chartContainer.appendChild(title);
        }

        if (ref === 'bar_chart') {
          let title = document.createElement('h2');
          title.textContent = `${this.$t('Puntaje de la categoría')}`;
          chartContainer.appendChild(title);
        }

        if (ref === 'principles_radar_chart') {
          let title = document.createElement('h2');
          title.textContent = `${this.$t('Distribución de los criterios de valoración')}`;
          chartContainer.appendChild(title);
        }

        // Agregar el gráfico al contenedor
        let chart = this.$refs[ref].$el.cloneNode(true);
        chart.classList.add('chart-element')

        if (ref === 'bar_chart' || ref === 'principles_radar_chart') {
          chart.classList.add('page-break')
        }
        chartContainer.appendChild(chart);

        // Agregar el contenedor del gráfico al contenedor de impresión
        printContainer.appendChild(chartContainer);
      });

      // Imprimir todos los gráficos a la vez
      this.printer.print(printContainer, [cssText])
    },
    printCategoryCharts(index) {
      let printContainer = document.createElement('div');
      printContainer.classList.add('charts-container');

      //agregar header
      printContainer.appendChild(this.generalDescription);

      this.pushCategoryToPrint(index, printContainer)

      this.printer.print(printContainer, [this.categoryChartCSS]);
    },
    pushCategoryToPrint(index, printContainer) {
      let title = document.createElement('h2');
      title.textContent = `${index + 1} - ${this.instrument === 'School_instrument' ? this.schoolCategoryParser[index + 1] : this.preschoolCategoryParser[index + 1]}`;
      printContainer.appendChild(title);

      const sharedContainer = document.createElement('div');
      sharedContainer.classList.add('shared-container')
      printContainer.appendChild(sharedContainer);

      const bloqueGraficos = document.getElementById(`category_${index}`);
      // Selecciona todos los gráficos dentro del bloque
      const graficos = bloqueGraficos.querySelectorAll('.charts');

      graficos.forEach((grafico, idx) => {
        const cloneChart = grafico.cloneNode(true);
        cloneChart.classList.add(`chart-${idx}`)

        if (idx === 0 || idx === 1) {
          sharedContainer.appendChild(cloneChart)
        } else {
          let chartContainer = document.createElement('div');
          chartContainer.classList.add("chart-print-container");

          if (idx === 2 || idx === 4) {
            cloneChart.classList.add('page-break')
          }

          if (idx === 3) {
            let title = document.createElement('h3');
            title.textContent = `${this.$t('Por indicador')}`;
            chartContainer.appendChild(title);
          }

          if (idx === 4) {
            let title = document.createElement('h3');
            title.textContent = `${this.$t('Puntaje del indicador')}`;
            chartContainer.appendChild(title);
          }

          chartContainer.appendChild(cloneChart);
          printContainer.appendChild(chartContainer);
        }
      });
    },
    printFullSection() {
      if (!this.isActionPlan && this.tab === 1) {
        let printContainer = document.createElement('div');
        printContainer.classList.add('charts-container');
        //agregar header
        printContainer.appendChild(this.generalDescription);

        this.$refs.categories_charts.forEach((el, index) => {
          this.pushCategoryToPrint(index, printContainer)
        })

        this.printer.print(printContainer, [this.categoryChartCSS]);
      }
      if (this.isActionPlan) {
        //agregar header
        this.$refs.action_plan_tab.printFullPlan()
      }
    },
    copyGeneralDescription() {
      const node = document.createElement('div');

      this.evaluationData.forEach(item => {
        const span = document.createElement('span');
        span.textContent = item;
        node.appendChild(span);
      });

      node.classList.add('general-description')
      this.generalDescription = node
    },
  },
  mounted() {
    //initialize printd
    this.printer = new Printd()
  },
  computed: {
    userData() { return this.$store.getters['session/userData']() },

    //info: schoolCategoryParser & preschoolCategoryParser imported from mixins
    percentagesParser() {
      const parser = {
        1: this.$t('Prácticas-autonomía'),
        2: this.$t('Trabajo colaborativo'),
        3: this.$t('Comunicación transversal'),
        4: this.$t('Inclusión educativa'),
      }
      return parser
    },
    chartSeries() {
      return {
        totalScoreSeries: [],
        generalScoreSeries: [{ data: [] }],
        principlesRadarChartSeries:  [{ data: [] }],
        categorySeries:[],
        categoryesRadarChartSeries: [
          [{ data:[] }],
        ],
        indicatorsBarSeries: [
          [{ data:[] }],
        ],
        categoryTotals: []
      }
    },
  },
  components: {
    VueApexCharts,
    NotesModal,
    PrintHelperModal,
    ScoresActionPlan
  },
  setup() {
    const icons = {
      mdiClose,
      mdiChevronDown,
      mdiHelpCircleOutline,
      mdiPencilOutline,
      mdiTrashCanOutline,
      mdiPlus,
      mdiPrinter,
      mdiCommentMultipleOutline,
      mdiCalendar
    }

    const categoryChartCSS = `

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      html {
        font-family: "AvenirNext", "Inter", sans-serif !important;
      }

      .charts-container {
        padding: 10px;
      }

      .show-notes {
        display: none;
      }

      .general-description {
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 15px;
        font-size: 19px;
        padding-bottom: 30px;
      }

      h2 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 28px;
      }

      h3 {
        margin-top: 25px;
        margin-bottom: 25px;
        font-size: 26px;
      }

      .page-break {
        page-break-after: always;
      }

      .apexcharts-toolbar, .apexcharts-tooltip, .apexcharts-yaxistooltip, .apexcharts-legend, .apexcharts-yaxistooltip{
        display: none !important;
        stroke: none !important;
      }

      .apexcharts-ycrosshairs{
        border: none !important;
        stroke: none !important;
      }

      .apexcharts-datalabel, .apexcharts-text {
        fill: #000 !important;
        font-size: 18px;
        font-weight: 700;
      }

      .shared-container {
        max-width: 600px !important;
        display: flex;
        align-items:center;
        margin-bottom: 20px;
      }

      .shared-container .charts {
        width: 250px;
      }

      table {
        width: 100% !important;
        border-collapse: collapse;
        font-size: 18px;
      }

      th, td {
        width: 50% !important;
        border: 2px solid #000 !important;
        padding: 10px;
        text-align: left;
      }

      th {
        background-color: #f2f2f2;
      }
      td .row {
        display: flex;
      }
      td .row .col {
        padding-left: 15px;
        width: 100%;
      }

      .only-print td{
        text-align: center;
        padding: 0 auto;
      }

    `

    return {
      categoryChartCSS,
      icons
    }
  }
}
</script>

<style scoped lang="scss">

.hidden-indicators {
  visibility: hidden;
  position: absolute;
}

.margins-breakpoints {
  margin-right: auto;
}

.only-print{
  display: none;
}
.print-chart-actions {
  position: absolute;
  left: 80%;
}

@media (min-width: 1264px) {
  .margins-breakpoints {
    margin-right: initial;
  }
}

.general-description {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.graphics-border {
  border-right: solid 1px rgba(94, 86, 105, 0.14);
}

.row-with-border {
  border-top: 2px solid rgba(94, 86, 105, 0.14);
  border-bottom: 2px solid rgba(94, 86, 105, 0.14);
  padding: 8px 0px;
}

th, td {
  width: 33%;
  border-right: solid 1px rgba(94, 86, 105, 0.14);
}
.active-tab
{
  background-color: #4d80c4;
}

</style>
