<template>
  <div>
  <v-card>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        id="name_searcher"
        :aria-label="t('Buscar por nombre de escuela')"
        :placeholder="t('Buscar por nombre de escuela')"
        outlined
        hide-details
        dense
        class="me-3 mb-4"
        v-model="searchQuery"
        @keyup="handleSearch"
      >
      <!-- v-model="searchQuery" -->
        <!-- @keyup="handleSearch" -->
      </v-text-field>
      <v-spacer></v-spacer>

        <v-select
					style="width: 0px"
					class="mb-4 mr-1"
					:items="[{name: '-', id: -1},...countryList]"
					hide-details="true"
					:label="t('País')"
					item-text="name"
					item-value="name"
					outlined
					dense
					v-model="countryFilter"
					@change="setFilterCountry"
					>
          <template v-slot:selection="data">
            {{ countryParser[data.item.name] ? countryParser[data.item.name] : '-' }}
          </template>

          <template v-slot:item="data">
            {{ countryParser[data.item.name] ? countryParser[data.item.name] : '-' }}
          </template>
        </v-select>

        <v-select
        style="width: 0px"
        class="mb-4 mr-1"
        :items="[{name: '-', id: -1},...regionList]"
        hide-details="true"
        :label="t('Region')"
        item-text="name"
        item-value="name"
        outlined
        dense
        v-model="regionFilter"
        @change="setFilterRegion"
        ></v-select>

        <v-btn
          v-if="getUserRole !== 3"
          color="primary"
          class="mb-4 me-3"
          @click="$refs.evaluation_form_modal.openModal()"
          >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>{{t('Crear Evaluación')}}</span>
        </v-btn>

    </v-card-text>
      <!-- table -->
      <v-data-table
        :no-data-text="getError ? getError : $t('No hay datos disponibles.')"
        :headers="tableColumns"
        :items="evaluationListTable"
        :page="current_page"
        :server-items-length="totalEvaluationListTable"
        :loading="loading"
        @update:page="changingEvaluationsPage($event)"
        @update:items-per-page="changingEvaluationsPerPage($event)"
        :footer-props="{
            'items-per-page-options': [
              10,
              25,
              50,
              100
            ],
            'items-per-page-text': `${$t('Items por página')}`,
            'page-text': `{0}-{1} ${$t('de')} {2}`,
          }"
      >

      <template #[`item.name`]="{item}">
        <td style="max-width: 325px;">
          <span
          class=" no-uppercase font-weight-medium text--primary">
            {{ item.name ? item.name : ' - '}}
          </span>
        </td>
      </template>

      <template #[`item.instrument`]="{item}">
        <div
        class=" no-uppercase font-weight-semibold text--primary">
          {{ instrumentParser[item.instrument]}}
        </div>
      </template>

      <template #[`item.date`]="{ item }">
        <div>
          <strong>{{ item.period ? item.period : '-' }}</strong>
          <br>
          <small>{{ item.date }}</small>
        </div>
      </template>

      <!-- action -->
      <template #[`item.edit`]="{ item }">
        <v-btn
          class=" icon-with-label"
          color="alt-primary"
          text
          @click="openEvaluation(item)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Editar')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </v-btn>
      </template>

      <!-- action plan -->
      <template #[`item.action_plan`]="{ item }">
        <v-btn
          class=" icon-with-label"
          color="alt-primary"
          text
          @click="$refs.scores_modal.openScoresModal(item, true)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Editar')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </v-btn>
      </template>

      <template #[`item.results`]="{ item }">
        <v-tooltip top v-if="!item.result">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                :disabled="!item.result"
                class="me-2 icon-with-label"
                text
                color="alt-primary"
              >
                <div class="d-flex align-center">
                  <span class="label">
                    {{$t('Ver')}}
                  </span>
                  <v-icon size="1.5rem">
                    {{ icons.mdiChartBar }}
                  </v-icon>
                </div>
              </v-btn>
            </div>
          </template>
          <div>
            <div>{{$t('Debe cargar algún indicador')}}<v-icon color="error" class="ms-2">{{ icons.mdiPencilOutline }}</v-icon></div>
            <div>{{$t('para poder visualizar gráficos')}}</div>
          </div>
        </v-tooltip>

        <v-btn v-else
          :disabled="!item.result"
          class="me-2 icon-with-label"
          text
          color="alt-primary"
          @click="$refs.scores_modal.openScoresModal(item, false)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Ver')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiChartBar }}
            </v-icon>
          </div>
        </v-btn>

      </template>

      </v-data-table>

    <evaluation-form-modal
      ref="evaluation_form_modal"
      @reload-evaluation-list="getEvaluationList()"
    />

    <school-instrument ref="school_instrument"
      @reload-evaluation-list="getEvaluationList()"
    />

    <scores-modal ref="scores_modal"/>

    <delete-evaluation-modal
      ref="delete_evaluation_modal"
      @reload-evaluation-list="getEvaluationList()"
    />

  </v-card>
  </div>
</template>

<script>
// const debounce = require('debounce');

// import data from './datatable'
import { ref } from '@vue/composition-api';
import EvaluationFormModal from '@/components/Evaluations/EvaluationFormModal';
import DeleteEvaluationModal from '@/components/Evaluations/DeleteEvaluationModal';
import SchoolInstrument from '@/components/Instruments/SchoolInstrument'
import countriesParserMixin from '@/utils/countriesParserMixin';
import ScoresModal from '@/components/Evaluations/ScoresModal'
import { useUtils } from '@core/libs/i18n'
import debounce from '../../plugins/debounce.js'

import {
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiChartBar
} from '@mdi/js'


export default {
  components: {
    EvaluationFormModal,
    DeleteEvaluationModal,
    SchoolInstrument,
    ScoresModal
  },
  mixins: [countriesParserMixin],
  data: ()  => {
    return {
      current_page: 1,
      page_size: 10,
      loading: false,
      getError: null,
      countryList: [],
      regionList: [],
      countryFilter: -1,
      regionFilter: -1
    }
  },
  methods: {
    async getEvaluationList() {
      this.loading = true
      try {
        const query = {
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
          country: this.countryFilter,
          region: this.regionFilter
        }
        const response = await this.$api.getEvaluationList(query)
        this.evaluationListTable = response.results
        this.totalEvaluationListTable = response.count
      } catch (error) {
        if(error?.response?.status === 403) {
          this.getError = error?.response?.data?.detail
        }
      } finally {
        this.loading = false
      }
    },
    changingEvaluationsPage(e) {
      this.current_page = e
      this.getEvaluationList()
    },
    changingEvaluationsPerPage(e) {
      this.page_size = e
      this.getEvaluationList()
    },
    handleSearch: debounce(function() {
        this.current_page = 1;
        this.getEvaluationList();
      }, 400),

    async getCountryList() {
      try {
        const response = await this.$api.getCountryList({ paginated: false })
        this.countryList = response
      } catch(e) {
        console.log(e)
      }
    },

    async getRegionList() {
      try {
        const query = { paginated: false, country: this.countryFilter, }
        const response = await this.$api.getRegionList(query)
        this.regionList = response
      } catch(e) {
        console.log(e)
      }
    },

    setFilterCountry() {
      this.current_page = 1
      this.getEvaluationList()
      this.regionFilter = -1
      this.getRegionList()
		},

    setFilterRegion() {
      this.current_page = 1
      this.getEvaluationList()
    },

    async openEvaluation(item) {
      try {
        const evaluation = await this.$api.getEvaluation(item.id)
        const instrument = await this.$api.getInstrument(item.instrument_id)
        this.$refs.school_instrument.open(instrument, false, evaluation)
      } catch(e) {
        console.log(e)
      }
    },
    async openScoresModal(item) {
      try {
        const response = await this.$api.getResults(item.id)
      } catch(e) {
        console.log(e)
      }
    }
  },
  mounted() {
  this.getEvaluationList()
  this.getCountryList()
  this.getRegionList()
  },
  computed: {
    tableColumns() {
      return [
      { text: this.$t('Título'), value: 'name', align: 'left', sortable:false,},
      { text: this.$t('Instrumento'), value: 'instrument', align: 'center', sortable:false},
      { text: this.$t('Escuela'), value: 'school', align: 'center', sortable: false },
      { text: this.$t('Período'), value: 'date', align: 'center', sortable: false },
      { text: this.$t('Evaluación'), value: 'edit', align: 'center', sortable: false },
      { text: this.$t('Plan de acción'), value: 'action_plan', align: 'center', sortable: false },
      { text: this.$t('Puntaje'), value: 'results', align: 'center', sortable: false }
      ]
    },
    instrumentParser() {
      const parser = {
        School_instrument: this.$t('Instrumento Escolar'),
        Pre_school_instrument: this.$t('Instrumento Preescolar')
      }
      return parser
    },
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  setup() {
    const totalEvaluationListTable = ref(0);
    const evaluationListTable = ref([]);
    const searchQuery = ref('');
    const { t } = useUtils()

    return {
      totalEvaluationListTable,
      evaluationListTable,
      searchQuery,
      t,
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiChartBar
      },
    }
  },
}
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
</style>
