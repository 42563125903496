<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>{{t('Evaluaciones')}}</v-card-title>
        <evaluation-list/>
      </v-card>
      <div style="display: none;" @click="methodsContainer.algo()">handleError</div>
    </div>
  </template>
  
  <script>
  import { useUtils } from '@core/libs/i18n'
  import EvaluationList from '@/components/Evaluations/EvaluationList.vue'

  export default {
   components: {
    EvaluationList,
},
   setup() {
    const { t } = useUtils()
      return {
        t,
        // icons
        icons: {
          
        },
        methodsContainer: {},
      }
    },
  
  }
  </script>