<template>
  <v-row no-gutters>
    <!-- <v-col cols="3" lg="3" md="2" sm="1" :class="$vuetify.theme.dark ? 'side-filler-dark' : 'side-filler'"></v-col>
  <v-col cols="6" lg="6" md="8" sm="10"> -->
    <!-- <v-col cols="3" lg="3" md="2" sm="1" :class="$vuetify.theme.dark ? 'side-filler-dark' : 'side-filler'"></v-col> -->
    <v-col class="px-12">
      <v-card class="mt-5" color="f6faff">
        <div ref="print_questions" class="page-break">
          <v-row>
            <v-col class="py-0 text-body-1 base-row" :class="$vuetify.theme.dark ? 'row-dark' : 'row-light'" style="position:relative">
              <v-card-title class="justify-left pa-4 text-h6"> {{ $t('Preguntas y respuestas') }} </v-card-title>

              <!-- :color="$vuetify.theme.dark? 'warning' : 'primary'"  -->
              <v-btn
                small
                text
                color="info"
                class="me-2 mr-1 icon-with-label small"
                style="position: absolute; top: 0px; right: 1rem; padding: 29px"
                aria-label="print"
                @click="printQuestionsAnswers()"
              >
                <span class="label">{{ $t('Imprimir') }}</span>
                <v-icon >{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-card-text v-if="!questions.length" class="text-center">
              <!-- si no existe, crear plan de accion y preguntas modelo.  -->
              <h3 class="mt-3 mb-2"><b>{{ $t('Esta evaluación no tiene un plan de acción') }}</b></h3>
              <v-btn small color="primary" @click="createActionPlan()"> {{ $t('Crear') }} </v-btn>
            </v-card-text>
            <v-card-text v-else>
              <ol style="list-style: upper-roman;">
                <li v-for="(field, idx) in questions" class="my-2" :key="`question_${idx}`">
                  <div><strong> {{ actionPlanParser[field.question] }}</strong></div>
                  <v-row v-if="field.isEditing">
                    <v-col class="mt-2 pb-">
                      <v-textarea class="mb-2"
                        v-model="field.answer_copy"
                        hide-details
                        outlined
                        name="input-7-4"
                        :label="$t('Editando') + '...'"
                        rows="3"
                      ></v-textarea>
                      <div class="d-flex">
                        <v-spacer></v-spacer>

                        <v-btn
                          small
                          outlined
                          color="tertiary"
                          @click="cancelEditingAnswer(field)"
                        >
                          {{ $t('Cancelar') }}
                        </v-btn>
                        <v-btn
                          small
                          :outlined="$vuetify.theme.dark"
                          :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                          class="ms-2"
                          @click="updateAnswer(field, 'edit')"
                        >
                          {{ $t('Guardar') }}
                        </v-btn>

                      </div>
                    </v-col>
                  </v-row>
                  <div v-if="!field.isEditing">
                    <span v-if="!field.answer.length" class="font-italic">{{ `${userRole !== 3 ? $t('Completar campo') : $t('No hay información aun cargada')}` + '...' }}</span>
                    <span>{{ field.answer }} </span>

                    <v-btn
                      v-if="userRole !== 3"
                      class="ms-3 me-2 icon-with-label"
                      :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                      text
                      @click="startEditingAnswer(field)"
                    >
                      <div class="d-flex align-center">
                        <span class="label">
                          {{ $t('Editar') }}
                        </span>
                        <v-icon size="1.5rem">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </div>
                    </v-btn>

                    <v-menu offset-x right v-if="userRole !== 3">
                      <template v-slot:activator="{ on, attrs }">

                        <v-btn
                          class="me-2 icon-with-label"
                          color="error"
                          text
                          v-on="on"
                          v-bind="attrs"
                        >
                          <div class="d-flex align-center">
                            <span class="label">
                              {{ $t('Eliminar') }}
                            </span>
                            <v-icon size="1.5rem">
                              {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                          </div>
                        </v-btn>

                      </template>
                      <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
                        <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar esta respuesta') + '?' }}</b></v-card-subtitle>
                        <v-card-text class="text-center">

                          <v-btn
                            small
                            outlined
                            color="tertiary"
                            class="me-3"
                          >
                            {{ $t('Cancelar') }}
                          </v-btn>
                          <v-btn
                            small
                            color="error"
                            :outlined="$vuetify.theme.dark"
                            @click="updateAnswer(field, 'delete')"
                          >
                            {{ $t('Eliminar') }}
                          </v-btn>

                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </li>
              </ol>
            </v-card-text>
          </v-row>
        </div>
        <div ref="print_goals">
          <div :id="`print_goal${idx}`" v-for="(goal, idx) in goalsAndResult" class="goal page-break" :key="`print_goal_${idx}`">
            <v-row>
              <v-col class="py-0 text-body-1 base-row" :class="$vuetify.theme.dark ? 'row-dark' : 'row-light'" style="position:relative">
                <v-card-title class="justify-left pa-4 text-h6"> {{ $t('Meta') + ' #' + (idx + 1) }} </v-card-title>

                <div style="position: absolute; top: 0px; right: 1rem;">
                  <v-menu offset-x right v-if="userRole !== 3 && goalsAndResult.length > 1" >
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn
                        class="me-2 icon-with-label small"
                        small
                        :color="$vuetify.theme.dark ? '#FFA299' : '#CC0047'"
                        text
                        v-on="on"
                        v-bind="attrs"
                        style="padding: 29px"
                      >
                        <div class="d-flex align-center">
                          <span class="label">
                            {{ $t('Eliminar') }}
                          </span>
                          <v-icon size="1.5rem">
                            {{ icons.mdiTrashCanOutline }}
                          </v-icon>
                        </div>
                      </v-btn>

                    </template>
                    <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
                      <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar esta meta') + '?' }} </b></v-card-subtitle>
                      <v-card-text class="text-center">
                        <v-btn
                          small
                          outlined
                          color="tertiary"
                          class="me-3"
                        >
                          {{ $t('Cancelar') }}
                        </v-btn>
                        <v-btn
                          small
                          color="error"
                          :outlined="$vuetify.theme.dark"
                          @click="deleteGoalsAndResult(goal)"
                        >
                          {{ $t('Eliminar') }}
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-menu>

                  <!-- :color="$vuetify.theme.dark? 'warning' : 'primary'"  -->
                  <v-btn
                    small
                    text
                    color="info"
                    class="me-2 mr-1 icon-with-label small"
                    aria-label="print"
                    style="padding: 29px"
                    @click="printGoalsActivities(idx)"
                  >
                    <span class="label">{{ $t('Imprimir') }}</span>
                    <v-icon >{{ icons.mdiPrinter }}</v-icon>
                  </v-btn>
                </div>


              </v-col>
            </v-row>
            <v-row v-if="questions.length">
              <v-col cols="12" class="py-0 my-3">
                <div>
                  <v-card-subtitle class="font-weight-bold">{{ $t('Meta a cumplir') + ':' }}</v-card-subtitle>
                  <v-card-text v-if="!goal.isEditingGoal">

                    <span v-if="goal.goals !== null && goal.goals.length">
                      {{ goal.goals }}
                    </span>
                    <span v-else class="font-italic">
                      {{ `${userRole !== 3 ? $t('Completar campo') : $t('No hay información aun cargada')}...` }}
                    </span>

                    <v-btn
                      v-if="userRole !== 3"
                      class="ms-3 me-2 icon-with-label"
                      :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                      text
                      @click="startEditingGoalsAndResult(goal, 'goal')"
                    >
                      <div class="d-flex align-center">
                        <span class="label">
                          {{ $t('Editar') }}
                        </span>
                        <v-icon size="1.5rem">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </div>
                    </v-btn>

                    <v-menu offset-x right v-if="userRole !== 3">
                      <template v-slot:activator="{ on, attrs }">

                        <v-btn
                          class="me-2 icon-with-label"
                          color="error"
                          text
                          v-on="on"
                          v-bind="attrs"
                        >
                          <div class="d-flex align-center">
                            <span class="label">
                              {{ $t('Eliminar') }}
                            </span>
                            <v-icon size="1.5rem">
                              {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                          </div>
                        </v-btn>

                      </template>

                      <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
                        <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar esta meta') + '?' }} </b></v-card-subtitle>
                        <v-card-text class="text-center">
                          <v-btn
                            small
                            outlined
                            color="tertiary"
                            class="me-3"
                          >
                            {{ $t('Cancelar') }}
                          </v-btn>
                          <v-btn
                            small
                            color="error"
                            :outlined="$vuetify.theme.dark"
                            @click="updateGoalsAndResult('delete', 'goal', goal)"
                          >
                            {{ $t('Eliminar') }}
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-menu>

                  </v-card-text>
                  <v-card-text v-else>
                    <v-textarea
                      v-model="goal.goals_copy"
                      outlined
                      name="input-7-4"
                      :label="$t('Editando') + '...'"
                      rows="3"
                      hide-details
                    ></v-textarea>
                    <div class="d-flex pt-2">
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        outlined
                        color="tertiary"
                        @click="cancelEditingGoalsAndResult(goal, 'goal')"
                      >
                        {{ $t('Cancelar') }}
                      </v-btn>
                      <v-btn
                        small
                        :outlined="$vuetify.theme.dark"
                        :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                        class="ms-2"
                        @click="updateGoalsAndResult('edit', 'goal', goal)"
                      >
                        {{ $t('Guardar') }}
                      </v-btn>
                    </div>
                  </v-card-text>
                </div>
                <div>
                  <v-card-subtitle class="font-weight-bold">{{ $t('Resultado esperado') + ':' }}</v-card-subtitle>
                  <v-card-text v-if="!goal.isEditingResult">
                    <span v-if="goal.result !== null && goal.result.length">{{ goal.result }}</span>
                    <span v-else class="font-italic">{{ `${userRole !== 3 ? $t('Completar campo') : $t('No hay información aun cargada')}` + '...' }}</span>

                    <v-btn
                      v-if="userRole !== 3"
                      class="ms-3 me-2 icon-with-label"
                      :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                      text
                      @click="startEditingGoalsAndResult(goal, 'result')"
                    >
                      <div class="d-flex align-center">
                        <span class="label">
                          {{ $t('Editar') }}
                        </span>
                        <v-icon size="1.5rem">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </div>
                    </v-btn>

                    <v-menu offset-x right v-if="userRole !== 3">
                      <template v-slot:activator="{ on, attrs }">

                        <v-btn
                          class="me-2 icon-with-label"
                          color="error"
                          text
                          v-on="on"
                          v-bind="attrs"
                        >
                          <div class="d-flex align-center">
                            <span class="label">
                              {{ $t('Eliminar') }}
                            </span>
                            <v-icon size="1.5rem">
                              {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                          </div>
                        </v-btn>

                      </template>

                      <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
                        <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar este resultado') + '?' }}</b></v-card-subtitle>
                        <v-card-text class="text-center">

                          <v-btn
                            small
                            outlined
                            color="tertiary"
                            class="me-3"
                          >
                            {{ $t('Cancelar') }}
                          </v-btn>
                          <v-btn
                            small
                            color="error"
                            :outlined="$vuetify.theme.dark"
                            @click="updateGoalsAndResult('delete', 'result', goal)"
                          >
                            {{ $t('Eliminar') }}
                          </v-btn>

                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-textarea
                      v-model="goal.result_copy"
                      outlined
                      name="input-7-4"
                      :label="$t('Editando') + '...'"
                      rows="2"
                      hide-details
                    ></v-textarea>
                    <div class="d-flex pt-2">
                      <v-spacer></v-spacer>

                      <v-btn
                        small
                        outlined
                        color="tertiary"
                        @click="cancelEditingGoalsAndResult(goal, 'result')"
                      >
                        {{ $t('Cancelar') }}
                      </v-btn>
                      <v-btn
                        small
                        :outlined="$vuetify.theme.dark"
                        :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                        class="ms-2"
                        @click="updateGoalsAndResult('edit', 'result', goal)"
                      >
                        {{ $t('Guardar') }}
                      </v-btn>
                    </div>
                  </v-card-text>
                </div>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="py-0 my-3">
                <v-card-subtitle class="font-weight-bold">
                  <span>{{ $t('Observaciones') + ':' }}</span>
                  <v-btn
                    icon
                    small
                    aria-label="Create new observation"
                    v-if="userRole !== 3"
                    :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                    class="ms-3"
                    @click="createObservation(goal)"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </v-card-subtitle>
                <v-card-text v-if="!goal.observations.length">
                  <span class="font-italic">{{ $t('Aún no hay observaciones') }}</span>
                </v-card-text>
                <v-card-text v-for="(observation, idx) in goal.observations" :key="`goal_element_${idx}`" class="mb-3">

                  <div v-if="!observation.isEditing" class="d-flex observation"  :class="{ 'no-border': (idx+1) === goal.observations.length }">
                    <v-col cols="7" class="py-0 ps-0" style="border-right: solid 1px rgba(94, 86, 105, 0.14);">
                      <span v-if="observation.text !== null && observation.text.length">{{ observation.text }}</span>
                      <span v-else class="font-italic">{{ `${userRole !== 3 ? $t('Completar campo') : $t('No hay información aun cargada')}` + '...' }}</span>
                    </v-col>
                    <v-col cols="5" class="py-0" >
                      <span v-if="observation.date !== null && observation.date.length">{{ $t('Fecha') + ': ' + observation.date }}</span>
                      <span v-else class="font-italic">{{ `${userRole !== 3 ? $t('Completar campo') : $t('No hay información aun cargada')}` + '...' }}</span>

                      <v-btn
                        v-if="userRole !== 3"
                        class="ms-3 me-2 icon-with-label"
                        :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                        text
                        @click="startEditingObservation(observation)"
                      >
                        <div class="d-flex align-center">
                          <span class="label">
                            {{ $t('Editar') }}
                          </span>
                          <v-icon size="1.5rem">
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                        </div>
                      </v-btn>

                      <v-menu offset-x right v-if="userRole !== 3">
                        <template v-slot:activator="{ on, attrs }">

                          <v-btn
                            class="me-2 icon-with-label"
                            color="error"
                            text
                            v-on="on"
                            v-bind="attrs"
                          >
                            <div class="d-flex align-center">
                              <span class="label">
                                {{ $t('Eliminar') }}
                              </span>
                              <v-icon size="1.5rem">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                            </div>
                          </v-btn>

                        </template>
                        <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
                          <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar esta observación') + '?' }} </b></v-card-subtitle>
                          <v-card-text class="text-center">
                            <v-btn
                              small
                              outlined
                              color="tertiary"
                              class="me-3"
                            >
                              {{ $t('Cancelar') }}
                            </v-btn>
                            <v-btn
                              small
                              color="error"
                              :outlined="$vuetify.theme.dark"
                              @click="deleteObservation(observation)"
                            >
                              {{ $t('Eliminar') }}
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </div>

                  <div v-else class="d-flex">
                    <v-col cols="7">
                      <v-textarea
                        v-model="observation.text_copy"
                        outlined
                        name="input-7-4"
                        :label="$t('Editando') + '...'"
                        rows="3"
                        hide-details
                      ></v-textarea>
                    </v-col>
                    <v-col cols="5">
                      <span v-if="observation.date !== null && observation.date.length">{{ observation.date }}</span>
                      <span v-else class="font-italic">{{ `${userRole !== 3 ? $t('Completar campo') : $t('No hay información aun cargada')}` + '...' }}</span>
                      <div class="pt-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          small
                          outlined
                          color="tertiary"
                          @click="cancelEditingObservation(observation)"
                        >
                          {{ $t('Cancelar') }}
                        </v-btn>
                        <v-btn
                          small
                          :outlined="$vuetify.theme.dark"
                          :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                          class="ms-2"
                          @click="updateObservation(observation)"
                        >
                          {{ $t('Guardar') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="py-0 my-3">
                <v-card-subtitle class="font-weight-bold d-flex">
                  <v-col cols="7"><span>{{ $t('Actividades para cumplir meta') + ':' }}</span>
                    <v-btn
                      icon
                      small
                      v-if="userRole !== 3 && goal.activities.length && goal.activities.length < 20"
                      :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                      class="ms-3"
                      @click="createActivity(goal)"
                    >
                      <v-icon >{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </v-col>

                </v-card-subtitle>
                <v-card-text class="ps-1">
                  <ul>
                    <li v-if="!goal.activities.length" class="mb-3">
                      <span>{{ $t('Aún no hay actividades asignadas') }}</span>
                      <v-btn
                        icon
                        small
                        aria-label="Create new activity"
                        v-if="userRole !== 3"
                        :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                        class="ms-3"
                        @click="createActivity(goal)"
                      >
                        <v-icon >{{ icons.mdiPlus }}</v-icon>
                      </v-btn>
                    </li>
                    <li v-for="(activity, idx) in goal.activities" :style="{background: $vuetify.theme.dark ? '#3b355a' : '#E0ECFD' }"  class="pb-2 pt-2 rounded mb-3" :key="`goal_activity_${idx}`">

                      <div v-if="!activity.isEditing" class="d-flex flex-wrap">
                        <v-col cols="7" class="py-0 activity-text" style="border-right: solid 1px rgba(94, 86, 105, 0.14);">
                          <div class="font-weight-bold">{{ $t('Actividad') }}:</div>
                          <span v-if="!activity.activity.length" class="font-italic">{{ `${userRole !== 3 ? $t('Completar campo') : $t('No hay información aun cargada')}` + '...' }}</span>
                          <span> {{ activity.activity }}</span>
                          <div class="data-to-print" style="display:none;">
                            <span v-if="activity.duration">{{ $t('Semana') }}: {{ activity.duration }}</span>
                            <span class="status">{{ $t('Estado') }}: {{ activityStatusParser[activity.activity_status] }}</span>
                          </div>
                          <div class="data-to-print" style="display:none;">
                            <span class="">{{ $t('Modalidad') }}: {{ activityModalityParser[activity.modality] }}</span>
                            <span class="status">{{ $t('Categoría') }}: {{ activityCategories.find(el => el.value === activity.category).name }}</span>
                          </div>
                        </v-col>
                        <v-col cols="5" class="py-0 d-flex align-center">
                          <div style="width:60%;">
                            <div class="font-weight-bold activities-head">{{ $t('Semana') }}</div>
                            <v-text-field class="mr-3"
                              :value="activity.duration"
                              :prepend-inner-icon=" $vuetify.breakpoint.width > 1520 ? icons.mdiCalendar : ''"
                              :style="{ 'font-size': '16px !important' }"
                              outlined
                              hide-details
                              dense
                              readonly
                            ></v-text-field>
                          </div>
                          <div style="width:50%;">
                            <div class="font-weight-bold activities-head">{{ $t('Estado') }}</div>
                            <v-select
                              v-model="activity.activity_status"
                              aria-label="activity status"
                              class="status-select"
                              :items="[{ name: $t('Realizado'), value: 0 }, { name: $t('En progreso'), value: 1 }, { name: $t('No realizado'), value: 2 }]"
                              item-text="name"
                              item-value="value"
                              persistent-hint
                              outlined
                              dense
                              readonly
                              hide-details
                              :background-color="getItemColor(activity.activity_status)"
                            ></v-select>
                          </div>
                          
                        </v-col>
                        <v-col cols="3" class="">
                          <div class="font-weight-bold activities-head">{{ $t('Modalidad') }}</div>
                          <v-btn-toggle mandatory style="pointer-events:none" color="secondary" v-model="activity.modality">
                            <v-btn :value="0" v-if="activity.modality === 0">{{$t('Presencial')}}</v-btn>
                            <v-btn :value="1" v-if="activity.modality === 1">{{$t('Virtual')}}</v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col cols="4" class="">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <div class="font-weight-bold activities-head">{{ $t('Categoría') }}</div>
                                <v-select
                                  v-model="activity.category"
                                  aria-label="activity category"
                                  :items="activity.modality === 0 ? activityCategories.filter(el => el.value !== 1) : activityCategories"
                                  item-text="name"
                                  item-value="value"
                                  persistent-hint
                                  outlined
                                  dense
                                  readonly
                                  hide-details
                                  
                                >
                                </v-select>
                              </div>
                            </template>
                            <span>{{ activityCategories.find(el => el.value === activity.category).name }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="3" class=" activity-text">
                          <div class="font-weight-bold">{{ $t('Próximos acuerdos') }}:</div>
                          <span v-if="!activity.next_agreements || !activity.next_agreements.length" class="font-italic">{{ `${userRole !== 3 ? $t('Completar campo') : $t('No hay información aun cargada')}` + '...' }}</span>
                          <span> {{ activity.next_agreements }}</span>
                        </v-col>
                        <v-col cols="2">
                          
                            <v-btn
                              small
                              text
                              v-if="userRole !== 3"
                              :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                              class="ms-1 icon-with-label small"
                              @click="startEditingActivity(activity)"
                            >
                              <span class="label">{{ $t('Editar') }}</span> <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                            </v-btn>
                            <v-menu offset-x right v-if="userRole !== 3">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  small
                                  text
                                  color="error"
                                  class="ms-1 icon-with-label small"
                                  v-on="on"
                                  v-bind="attrs"
                                >
                                  <span class="label">{{ $t('Eliminar') }}</span>
                                  <v-icon >{{ icons.mdiTrashCanOutline }}</v-icon>
                                </v-btn>
                              </template>
                              <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
                                <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar esta actividad') + '?' }} </b></v-card-subtitle>
                                <v-card-text class="text-center">
                                  <v-btn
                                    small
                                    outlined
                                    color="tertiary"
                                    class="me-3"
                                  >
                                    {{ $t('Cancelar') }}
                                  </v-btn>
                                  <v-btn
                                    small
                                    color="error"
                                    :outlined="$vuetify.theme.dark"
                                    @click="deleteActivity(activity)"
                                  >
                                    {{ $t('Eliminar') }}
                                  </v-btn>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                        </v-col>
                      </div>

                      <div v-else class="d-flex flex-wrap">
                        <v-col cols="7" class="pb-0 pt-0">
                          <div class="font-weight-bold activities-head">{{ $t('Actividad') }}</div>
                          <v-textarea class="mb-0"
                          v-model="activity.activity_copy"
                          hide-details
                          outlined
                          name="input-7-4"
                          rows="2"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="5" class="d-flex pb-0">
                          <div style="width:50%">
                            <div class="font-weight-bold activities-head">{{ $t('Semana') }}</div>
                            <v-menu
                              ref="menu"
                              v-model="activity.menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <!-- :close-on-click="false" -->
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field class="mr-3"
                                  :value="activity.dates"
                                  :prepend-inner-icon="icons.mdiCalendar"
                                  outlined
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="activity.dates"
                                :first-day-of-week="0"
                                :locale="$i18n.locale === 'pt' ? 'pt-bt' : $i18n.locale === 'en' ? 'en-us' : 'es-es'"
                                range
                                no-title
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="activity.menu = false"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </div>
                          <div style="width:50%">
                            <div class="font-weight-bold activities-head">{{ $t('Estado') }}</div>
                            <v-select
                              v-model="activity.activity_status_copy"
                              :items="[{ name: $t('Realizado'), value: 0 }, { name: $t('En progreso'), value: 1 }, { name: $t('No realizado'), value: 2 }]"
                              item-text="name"
                              item-value="value"
                              hide-details
                              outlined
                              :background-color="getItemColor(activity.activity_status_copy)"
                            ></v-select>
                          </div>
                        </v-col>
                        <v-col cols="3" class="">
                          <div class="font-weight-bold" activities-head>{{ $t('Modalidad') }}</div>
                          <v-btn-toggle @change="resetCategorySelect(activity)" mandatory color="secondary" v-model="activity.modality">
                            <v-btn :value="0">{{$t('Presencial')}}</v-btn>
                            <v-btn :value="1">{{$t('Virtual')}}</v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col cols="4" class="">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <div class="font-weight-bold activities-head">{{ $t('Categoría') }}</div>
                                <v-select
                                  v-model="activity.category"
                                  aria-label="activity category"
                                  :items="activity.modality === 0 ? activityCategories.filter(el => el.value !== 1) : activityCategories"
                                  item-text="name"
                                  item-value="value"
                                  outlined
                                  hide-details
                                >
                                </v-select>
                              </div>
                            </template>
                            <span>{{ activityCategories.find(el => el.value === activity.category).name }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="4">
                          <div class="font-weight-bold activities-head">{{ $t('Próximos acuerdos') }}</div>
                          <v-textarea 
                          class="mb-2"
                          v-model="activity.next_agreements"
                          hide-details
                          outlined
                          name="input-7-4"
                          :label="$t('Editando') + '...'"
                          rows="2"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="1" class="d-flex flex-column justify-center">
                          <v-btn
                            small
                            :outlined="$vuetify.theme.dark"
                            :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                            class="mb-1 mt-3"
                            @click="updateActivity(activity, 'edit')"
                            >
                              {{ $t('Guardar') }}
                          </v-btn>
                          <v-btn
                            small
                            outlined
                            color="tertiary"
                            @click="cancelEditingActivity(activity)"
                          >
                            {{ $t('Cancelar') }}
                          </v-btn>
                        </v-col>
                      </div>

                    </li>
                  </ul>
                </v-card-text>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-row v-if="questions.length">
          <v-col class="py-0 text-body-1 base-row" :class="$vuetify.theme.dark ? 'row-dark' : 'row-light'">
            <v-card-title class="justify-center pa-1">
              <v-btn
                color="alt-primary"
                text
                @click="createGoalsAndResult()"
              >
                {{ $t('Agregar meta') }}
                <v-icon class="ml-3">{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </v-card-title>
          </v-col>
        </v-row>
      </v-card>

    </v-col>
    <!-- <v-col cols="3" lg="3" md="2" sm="1" :class="$vuetify.theme.dark ? 'side-filler-dark' : 'side-filler'"></v-col> -->
  </v-row>
</template>

<script>
export default {
  data: () => {
    return {
      questions: [],
      goalsAndResult: [{
        goals: null,
        result: null
      }],
      // activities: [],
      menu: false,
      // dates: [],
      // showDates: [],
    }
  },
  props: {
    generalDescription: HTMLElement,
    results: Object,
    icons: Object,
    printer: Object
  },
  computed: {
    userRole() { return this.$store.getters['session/getUserRole']() },
    userData() { return this.$store.getters['session/userData']() },
    actionPlanParser() {
      const parser = {
        0: this.$t('¿Cuáles son las necesidades y problemas identificados para atender prioritariamente en el proceso de cambio sobre el que se realiza el plan?'),
        1: this.$t('¿Cuáles son los centros de interés que motivan y movilizan al equipo de trabajo para participar del proceso de cambio?'),
        2: this.$t('¿Cuáles son los obstáculos y las dificultades que se pueden encontrar al momento de implementar el plan de acción para resolver los problemas identificados?'),
        3: this.$t('¿De qué recursos disponemos en el corto, mediano y largo plazo para llevar adelante un plan de cambio?'),
        4: this.$t('¿Cuáles son los resultados de la evaluación de los indicadores de calidad de Perkins que se deciden priorizar en el plan de acción?'),
      }
      return parser
    },
    activityCategories() {
      let items = [
        {value: 0, name: this.$t('Reuniones de seguimiento.')},
        {value: 1, name: this.$t('Comunicaciones por e-mail, WhatsApp.')},
        {value: 2, name: this.$t('Monitoreo sobre la implementación de contenidos recibidos desde la formación de la academia.')},
        {value: 3, name: this.$t('Monitoreo sobre actividades singulares/distintivas, prácticas referidas a casos individuales.')},
        {value: 4, name: this.$t('Otras formaciones.')},
        {value: 5, name: this.$t('Otras actividades.')},
      ]

      return items
    },
    activityStatusParser() {
      const parser = {
        0: this.$t('Realizado'),
        1: this.$t('En progreso'),
        2: this.$t('No realizado'),
      }
      return parser
    },
    activityModalityParser() {
      const parser = {
        0: this.$t('Presencial'),
        1: this.$t('Virtual'),
      }
      return parser
    },
  },
  methods: {
    getQuestions(results) {
      const questionsModel = [
        { question: 0, answer: '', isEditing: false },
        { question: 1, answer: '', isEditing: false },
        { question: 2, answer: '', isEditing: false },
        { question: 3, answer: '', isEditing: false },
        { question: 4, answer: '', isEditing: false },
      ]

      this.questions = questionsModel.map((question) => {
        if (results.action_plan && results.action_plan.questions.length) {
          const backendQuestion = results.action_plan.questions.find((q) => q.question === question.question);
          return { ...question, id: backendQuestion.id, answer: backendQuestion.answer, isEditing: false };
        }
        return question;
      });

      this.goalsAndResult = results.action_plan ? results.action_plan.goals : []

    },
    async createActionPlan() {
      try {
        const form = {
          evaluation: this.results.evaluation_id,
          user_info: this.userData.id
        }
        const actionPlan = await this.$api.createActionPlan(form);
        await this.createQuestions(actionPlan)
        await this.createGoalsAndResult(actionPlan)

      } catch (error) {
        console.log(error);
      }
    },
    resetCategorySelect(activity) {
      if( activity.category === 1) {
        activity.category = 0
      }
    },
    async createQuestions(actionPlan) {
      const numQuestions = 5;

      for (let i = 0; i < numQuestions; i++) {
        const questionData = {
          question: i,
          answer: '',
          action_plan: actionPlan.data.id
        };

        try {

          await this.$api.createQuestion(questionData);

        } catch (error) {
          console.log('Create questions error', error);
        }
      }

      this.$emit("reload-results", this.results.evaluation_id)
    },
    startEditingAnswer(field) {
      field.isEditing = true
      this.$set(field, 'answer_copy', field.answer)
    },
    cancelEditingAnswer(field) {
      field.isEditing = false
    },
    async updateAnswer(field, action) {
      // console.log(field);
      const form = {
        question: field.question,
        answer: action === 'edit' ? field.answer_copy : '',
        action_plan: this.results.action_plan.id
      }

      try {
        const response = await this.$api.editQuestion(field.id, form)

        field.isEditing = false
        this.$emit("reload-results", this.results.evaluation_id)

      } catch (error) {
        console.log(error);
      }
    },
    //GOALS & RESULT METHODS
    async createGoalsAndResult(actionPlan) {
      try {
        const form = {
          goals: null,
          result: null,
          action_plan: actionPlan ? actionPlan.data.id : this.results.action_plan.id
        }

        await this.$api.createGoalsAndResult(form);
        this.$emit("reload-results", this.results.evaluation_id)

      } catch (error) {
        console.log(error);
      }
    },
    startEditingGoalsAndResult(goal, label) {

      this.$set(goal, `${label === 'result' ? 'isEditingResult' : 'isEditingGoal'}`, true)
      this.$set(goal, `${label === 'result' ? 'result_copy' : 'goals_copy'}`, label === 'result' ? goal.result  : goal.goals)
    },
    cancelEditingGoalsAndResult(goal, label) {
      this.$set(goal, `${label === 'result' ? 'isEditingResult' : 'isEditingGoal'}`, false)
    },
    async updateGoalsAndResult(action, label, goal) {
      //* en estos campos solo se puede editar y eliminar con el mismo patch
      const form = {
        goals: action === 'edit' ? goal.goals_copy : label === 'goal' ? '' : goal.goals_copy,
        result: action === 'edit' ? goal.result_copy : label === 'result' ? '' : goal.result_copy,
      }

      try {
        const response = await this.$api.updateGoalsAndResult(goal.id, form)

        goal.isEditingGoal = false
        goal.isEditingResult = false
        this.$emit("reload-results", this.results.evaluation_id)

      } catch (error) {
        console.log(error);
      }
    },
    async deleteGoalsAndResult(goal) {
      try {
        await this.$api.deleteGoalsAndResult(goal.id)
        this.$emit("reload-results", this.results.evaluation_id)

      } catch (error) {
        console.log('error delete goal: ', error);
      }
    },
    //OBSERVATIONS METHODS
    startEditingObservation(observation) {

      this.$set(observation, 'isEditing', true)
      this.$set(observation, 'text_copy', observation.text)
    },
    cancelEditingObservation(observation) {
      this.$set(observation, 'isEditing', false)
    },
    async createObservation(goal) {
      try {
        const form = {
          goal: goal.id
        }
        const response = await this.$api.createObservation(form)
        this.$emit("reload-results", this.results.evaluation_id)
      } catch (error) {
        console.log('Observation error: ', error);
      }
    },
    async updateObservation(observation) {

      const form = {
        text: observation.text_copy,
        date: observation.date
      }

      try {
        const response = await this.$api.updateObservation(observation.id, form)

        observation.isEditing = false

        this.$emit("reload-results", this.results.evaluation_id)

      } catch (error) {
        console.log(error);
      }
    },
    async deleteObservation(observation) {
      try {
        const response = await this.$api.deleteObservation(observation.id)
        this.$emit("reload-results", this.results.evaluation_id)

      } catch (error) {
        console.log('error delete activity: ', error);
      }
    },

    //ACTIVITIES METHODS
    async createActivity(goal) {
      try {
        const form = {
          activity: '',
          activity_status: 2,
          goal: goal.id
        }
        const response = await this.$api.createActivity(form)
        this.$emit("reload-results", this.results.evaluation_id)
      } catch (error) {
        console.log('error en actividades: ', error);
      }
    },
    startEditingActivity(activity) {
      this.$set(activity, 'isEditing', true)
      this.$set(activity, 'activity_copy', activity.activity)
      this.$set(activity, 'dates', [this.parseDate(activity.start_date), this.parseDate(activity.end_date)])
      this.$set(activity, 'activity_status_copy', activity.activity_status)
    },
    cancelEditingActivity(activity) {
      this.$set(activity, 'isEditing', false)
    },
    parseDate(date) {
      if(date) {
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}`;
      }
    },
    getItemColor(item) {
      switch (item) {
        case 0:
          return `${this.$vuetify.theme.dark ? 'rgba(0, 255, 10, 0.20)' : 'rgba(113, 219, 35, 0.19)'}`; // Verde
        case 1:
          return `${this.$vuetify.theme.dark ? 'rgba(255, 229, 30, 0.38)' : 'rgba(255, 227, 0, 0.19)'} `; // Amarillo
        case 2:
          return `${this.$vuetify.theme.dark ? 'rgba(255, 28, 28, 0.35)' : 'rgba(255, 0, 0, 0.19)'}` // Rojo
        default:
          return ""; // Color predeterminado
      }
    },
    async updateActivity(activity, action) {

      // Validating dates order
      const [startDate, endDate] = activity.dates;
      const startDateObj = new Date(startDate + "T00:00:00Z");
      const endDateObj = new Date(endDate + "T00:00:00Z");
      const isStartDateEarlier = startDateObj < endDateObj;

      const form = {
        activity: activity.activity_copy,
        activity_status: activity.activity_status_copy,
        modality: activity.modality,
        category: activity.category,
        next_agreements: activity.next_agreements,
        start_date: isStartDateEarlier ? startDate : endDate ,
        end_date: isStartDateEarlier ? endDate : startDate
      }

      try {
        const response = await this.$api.updateActivity(activity.id, form)

        activity.isEditing = false

        this.$emit("reload-results", this.results.evaluation_id)

      } catch (error) {
        console.log(error);
      }
    },
    async deleteActivity(activity) {
      try {
        const response = await this.$api.deleteActivity(activity.id)
        this.$emit("reload-results", this.results.evaluation_id)

      } catch (error) {
        console.log('error delete activity: ', error);
      }
    },
    printQuestionsAnswers() {
      let printContainer = document.createElement('div');
      printContainer.appendChild(this.generalDescription);

      let questions = this.$refs.print_questions.cloneNode(true)
      printContainer.appendChild(questions)
      this.printer.print(printContainer, [this.actionPlanCSS])
    },
    printGoalsActivities(idx) {
      let printContainer = document.createElement('div');
      printContainer.appendChild(this.generalDescription);
      let goalsAndActivities = document.getElementById(`print_goal${idx}`).cloneNode(true)
      printContainer.appendChild(goalsAndActivities)
      this.printer.print(printContainer, [this.actionPlanCSS])
    },
    printFullPlan() {
      let printContainer = document.createElement('div');
      printContainer.appendChild(this.generalDescription);

      let questions = this.$refs.print_questions.cloneNode(true)
      let goalsAndActivities = this.$refs.print_goals.cloneNode(true)
      printContainer.appendChild(questions)
      printContainer.appendChild(goalsAndActivities)

      this.printer.print(printContainer, [this.actionPlanCSS])
    }
  },
  mounted () {
    this.getQuestions(this.results)
  },
  watch: {
    results: {
      immediate: true,
      handler(newResults) {
        if (Object.keys(newResults).length !== 0) {
          // console.log(newResults)
          this.getQuestions(newResults);
        }
      },
    },
  },
  setup() {
    const actionPlanCSS = `
      #main, div, span {
        font-family: "AvenirNext", "Inter", sans-serif !important;
        font-size: 17px;
        line-height: 1.4;
      }
      .general-description {
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 15px;
        font-size: 19px;
        padding-bottom: 30px;
      }

      .show-notes, .activities-head, .activity_dates {
        display: none;
      }

      .page-break {
        page-break-after: always;
      }

      .v-card__title{
        font-weight: 900;
        font-size: 24px;
      }

      .v-card__subtitle {
        font-weight: 800;
        margin-top: 15px;
      }

      .observation {
        border-bottom: solid 1px rgba(94, 86, 105, 0.14);
        margin-bottom: 15px;
      }

      .observation.no-border {
        border-bottom: none;
      }

      .observation .col{
        border-right: none !important;
        margin-bottom: 10px;
      }

      .data-to-print {
        display: flex !important;
        gap: 20px;
        justify-content: space-between !important;
        margin-bottom: 15px;
      }

      .data-to-print .status{
        padding: 4px;
        border: solid 1px rgba(94, 86, 105, 0.14);
      }

      .activity-text {
        border-right: none !important;
      }

      button, .v-input {
        display: none;
      }
    `
    return {
      actionPlanCSS
    }
  },
  // destroyed () {
  //   console.log('DESTROYYY');
  // },
}
</script>

<style lang="scss" scoped>
.status-select {
  max-width: 10rem;
}

.base-row {
  border: 1px solid rgba(94, 86, 105, 0.14);
}

// Clases específicas con diferencias de color
.row-light {
  @extend .base-row;
  background-color: #e7f1fd;
}

.row-dark {
  @extend .base-row;
  background-color: #4a456a;
}
</style>
