<template>
  <v-dialog v-model="showingEvaluationModal" width="700" scrollable persistent minHeight="1200">
    <v-form ref="evaluationForm" autocomplete="new-password" @submit.prevent="createEvaluation">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 pb-0">
          {{ editFormId? $t('Editar Evaluación') : $t('Crear Evaluación') }}
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="6">
              <v-text-field
              v-model="evaluation_name"
              :label="$t('Título')"
              :rules="[() => !!evaluation_name || $t('Campo requerido')]"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select
                :items="schoolList"
                hide-details="true"
                v-model="school"
                :label="$t('Escuela')"
                :rules="[() => !!school || $t('Campo requerido')]"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                :items="instrumentList"
                hide-details="true"
                v-model="instrument"
                :label="$t('Instrumento')"
                :rules="[() => !!instrument || $t('Campo requerido')]"
                item-text="name"
                item-value="id"
              >
                <template v-slot:selection="data">
                  {{ instrumentParser[data.item.name] }}
                </template>

                <template v-slot:item="data">
                  {{ instrumentParser[data.item.name] }}
                </template>
              </v-select>
            </v-col>

            <v-col cols="6">
              <v-text-field
              v-model="period"
              :label="$t('Período')"
              :rules="[() => !!period || $t('Campo requerido')]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="terciary"
            outlined
            @click="close()"
          >
            {{$t('Cancelar')}}
          </v-btn>
          <v-btn color="primary" type="submit">
            {{ editFormId ? $t('Editar Evaluación') : $t('Crear Evaluación') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  mdiCalendar
} from '@mdi/js'

export default {
  data: () => {
    return {
      editFormId: null,
      schoolList: [],
      school: null,
      instrumentList: [],
      instrument: null,
      evaluation_name: '',
      period: '',
      menu1: false,
      showingEvaluationModal: false
    }
  },
  methods: {
    openModal(id_to_edit) {
      this.getSchoolList()
      this.getInstrumentList()
      id_to_edit
          ? this.setItemToEdit(id_to_edit)
          : this.showingEvaluationModal = true;
    },
    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getEvaluation(id_to_edit);
      if (item) {
        this.editFormId = id_to_edit;
        this.school = item.school ?? null;
        this.showingEvaluationModal = true;
      }
    },
    async createEvaluation() {
        if (this.$refs.evaluationForm.validate()) {
        const form = this.parseForm();
        try {
          const response = this.editFormId
              ? await this.$api.updateEvaluation(this.editFormId, form)
              : await this.$api.createEvaluation(form);

          //si es una nueva evaluacion, crear un action_plan
          if(!this.editFormId) {
            await this.createActionPlan(response)
          }
          this.$emit("reload-evaluation-list");
          this.close()
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    async createActionPlan(response){
      try {
        const form = {
          evaluation: response.data.id,
          user_info: this.userData.id
        }
        const actionPlan = await this.$api.createActionPlan(form);
        await this.createQuestions(actionPlan)
        await this.createGoalsAndResult(actionPlan)
      } catch (error) {
        console.log(error);
      }
    },
    async createQuestions(actionPlan) {
      const numQuestions = 5;

      for (let i = 0; i < numQuestions; i++) {
        const questionData = {
          question: i,
          answer: '',
          action_plan: actionPlan.data.id
        };

        try {
          const createdQuestion = await this.$api.createQuestion(questionData);
        } catch (error) {
          console.log('Error al crear la pregunta:', error);
        }
      }
    },
    async createGoalsAndResult(actionPlan) {
      try {
        const form = {
          goals: null,
          result: null,
          action_plan: actionPlan ? actionPlan.data.id : this.results.action_plan.id
        }

        await this.$api.createGoalsAndResult(form);

      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.$refs.evaluationForm.reset()
      this.editFormId = null;
      this.showingEvaluationModal = false;
    },
    parseForm() {
      const form = {
        school: this.school,
        instrument: this.instrument,
        name: this.evaluation_name,
        period: this.period
      }
      const formParsed = JSON.parse(JSON.stringify(form));
      return formParsed;
    },
    async getSchoolList() {
      try {
        const query = {paginated: false}
        const response = await this.$api.getSchoolList(query)
        this.schoolList = response
      } catch(e) {
        console.log(e)
      }
    },
    async getInstrumentList() {
      try {
        const response = await this.$api.getInstrumentList()
        this.instrumentList = response.results
      } catch(e) {
        console.log(e)
      }
    }
  },
  computed: {
    userData() { return this.$store.getters['session/userData']() },
    instrumentParser() {
      const parser = {
        School_instrument: this.$t('Instrumento Escolar'),
        Pre_school_instrument: this.$t('Instrumento Preescolar')
      }
      return parser
    }
  },
  setup() {
    return {
      icons: {
        mdiCalendar
      }
    }
  }
}
</script>
